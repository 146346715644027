export const CONDITION_RULE: string = 'Condition in which this effect activates.';
export const YOU_CAN_ONLY_USE_RULL: string = 'If the activation of this effect is negated, you cannot activate it again this turn.';
export const YOU_CAN_ONLY_ACTIVATE_RULL: string = 'If the activation of this effect is negated, you CAN attempt to activate it with another copy of the card this turn.'
export const WHEN_TRIGGER_RULE: string = 'If not mandatory, this effect will try to activate immediately after its condition has been met, and only if it was the last thing to happen. If mandatory: activate in the next available chain.';
export const IF_TRIGGER_RULE: string = 'On an activated effect, this effect will activate in the next available chain.';
export const THEN_RESOLUTION_RULE: string = 'Do A then do B, B happens after A, this is still only 1 effect. You must be able to do all mandatory parts to activate. If A doesn\'t happen, stop resolution here. If B cannot happen anymore, still resolve A.';
export const AND_IF_YOU_DO_RESOLUTION_RULE: string = 'Do A, and if you do, do B, both actions are considered simultaneous, this is still only 1 effect. You must be able to do all mandatory parts to activate. If A doesn\'t happen, stop resolution here. If B cannot happen anymore, still resolve A.';
export const ALSO_RESOLUTION_RULE: string = 'Do A, also do B, both actions are considered simultaneous, this is still only 1 effect. A is not required to resolve B. B is not required for A to resolve.';
export const COST_RULE: string = 'Cost or action to perform in order to activate the card or effect. Must be done before any response. This effect creates a chain link.';

export const FOR_THE_REST_OF_THIS_TURN_RULE: string = 'This restriction applies only to your actions AFTER this part of the effect.';
export const DURING_THE_TURN_ACTIVATE_RULE: string = 'This restriction checks your actions PRIOR to activating this card or effect. If you have not respected this restriction earlier this turn, you cannot activate this card or effect.';
export const QUICK_EFFECT_RULE: string = 'This is a Spell Speed 2 effect. It can be activated in response to Spell Speed 1 and 2 effects, but not in response to Counter Traps. It is NOT a trigger effect.';
export const EXCAVATE_RULE: string = 'Excavating means revealing the cards to both players from the Deck. These cards are still considered to be in the Deck.';
export const CONTROL_RULE: string = 'Cards you “control” are the cards in your Monster Zone, Spell & Trap Zone, Field Zone, and Pendulum Zone.';
export const TRIBUTING_RULE: string = 'Tributing is sending a monster you control to the Graveyard. You can Tribute a face-up or face-down monster, unless otherwise specified.';
export const TRIBUTE_RULE: string = 'Tributing is sending a monster you control to the Graveyard. You can Tribute a face-up or face-down monster, unless otherwise specified.';
export const FLIP_RULE: string = '';
export const AT_DAMAGE_STEP_START_RULE: string = '';
export const DURING_DAMAGE_STEP_RULE: string = '';
export const AFTER_DAMAGE_STEP_RULE: string = '';
export const UNTIL_END_DAMAGE_STEP_RULE: string = '';
export const LEAVE_FIELD_RULE: string = 'Leaving the field means being sent to the GY, banished, returned to the hand or Extra Deck. A card can NOT activate its effect when it leaves the field if it is returned to the Main Deck, or if it is used as XYZ Material.';
export const SET_RULE: string = 'Setting a card means placing it face-down. Any face-down card on the field is Set.';
export const INSTEAD_RULE: string = '';
export const BEFORE_DAMAGE_RULE: string = '';
export const POSSESS_RULE: string= '';
export const ALSO_RULE: string= '';
