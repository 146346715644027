import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { FilterEmission } from 'src/app/utils/models/emissions/filterEmission';
import { AutocompletedSelectorComponent } from '../autocompleted-selector/autocompleted-selector.component';

@Component({
  selector: 'app-autocompleted-with-operator-selector',
  templateUrl: './autocompleted-with-operator-selector.component.html',
  styleUrls: ['./autocompleted-with-operator-selector.component.scss']
})
export class AutocompletedWithOperatorSelectorComponent implements OnInit {

  displayOperatorSelector = true;

  @Input("selection")
  selection: string[];

  @Input("keychar")
  keychar: string;

  @Input("labelName")
  labelName: string;

  @Input("selector")
  selector: boolean = false;

  @Input("isUnique")
  isUnique: boolean = false;

  @Input('operators')
  operators: string[];

  selectedOperator: any;

  @ViewChild('autocompletedSelector') autocompletedSelector: AutocompletedSelectorComponent;


  @Output('filterEmission')
  filterEmission: EventEmitter<FilterEmission> = new EventEmitter<FilterEmission>();

  constructor() { }

  ngOnInit(): void {
    if (this.operators.length === 0) {
      this.displayOperatorSelector = false;
    }
  }

  tryEmission() {
    this.autocompletedSelector.emitFilterString();
  }

  emitFilterString(emission: FilterEmission): void {
    this.filterEmission.emit(this.insertOperator(emission));
  }

  insertOperator(emission: FilterEmission): FilterEmission {
    if (!this.displayOperatorSelector) return emission;

    const operator = this.selectedOperator !== undefined ? this.selectedOperator : '=';
    emission.emission = emission.char + ' ' + operator + emission.emission.replace(emission.char, '').trim()

    return emission;
  }

  clean() {
    if (this.selectedOperator) this.selectedOperator = '';
    this.autocompletedSelector.clean();

  }
}
