import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterEmission } from '../../../utils/models/emissions/filterEmission';
import { isStringIn } from '../../../utils/string/string.util';

@Component({
  selector: 'app-autocompleted-selector',
  templateUrl: './autocompleted-selector.component.html',
  styleUrls: ['./autocompleted-selector.component.scss']
})
export class AutocompletedSelectorComponent implements OnInit {

  @Input("selection")
  selection: string[];

  @Input("keychar")
  keychar: string;

  @Input("labelName")
  labelName: string;

  @Input("selector")
  selector: boolean = false;

  @Input("isUnique")
  isUnique: boolean = false;

  @Output('filterEmission')
  filterEmission: EventEmitter<FilterEmission> = new EventEmitter<FilterEmission>();

  filteredValues: string[] = [];

  selectedElement: any;

  constructor() {
  }

  ngOnInit(): void {
    if (this.selector) this.selectedElement = '';
  }

  autocomplete(): void {
    if(!this.selector) return;
    this.filteredValues = this.selection.filter(value => isStringIn(value, this.selectedElement)).slice(0, 6);
  }

  getKey(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.emitFilterString();
    } else {
      this.autocomplete();
    }

  }

  emitFilterString(): void {
    if (this.selectedElement && this.selectedElement.trim() !== '') this.filterEmission.emit({ emission: this.getFilterString().trim(), isUnique: this.isUnique, char: this.keychar });
    if (!this.selector) this.selectedElement = '';
  }

  clean(): void {
    this.selectedElement = '';
  }

  getFilterString() {
    return this.keychar + ' ' + this.selectedElement.trim();
  }

  getValue(): string {
    return this.selectedElement.trim();
  }

}
