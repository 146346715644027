import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.scss']
})
export class LoginComponentComponent implements OnInit {

  form: FormGroup;

  constructor(private fb: FormBuilder,
    private logginService: AuthenticationService,
    private router: Router) {

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }


  ngOnInit(): void {

  }

  login() {
    const val = this.form.value;    
    if (val.username && val.password) {
      this.logginService.login(val.username, val.password)
        .subscribe(
          () => {
            console.log("User is logged in");
            this.router.navigateByUrl('/');
          }
        );
    }
  }

  register() {
    const val = this.form.value;
    if (val.username && val.password) {
      this.logginService.register(val.username, val.password)
        .subscribe(
          () => {
            console.log("User registration done");
            this.router.navigateByUrl('/');
          }
        );
    }
  }

}
