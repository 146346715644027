import { operations } from "../operations/operation.util";

export const ponctuationSigns: string[] = ['.', ';', ':', '!', ','];
export const comparators: string[] = operations.map(operation => operation.character);
const DEFAULT_COMPARATOR = '=';

export function isStringIn(src: string, match: string): boolean {
    if (!src || !match) {
        return false;
    }

    const lowerSrc = src.toLowerCase();
    const lowerMatch = match.toLowerCase().trim();

    const result: string[] = lowerSrc.split(lowerMatch.trim());

    if (result.length >= 2 || result[0] !== lowerSrc) {
        return true;
    }

    return false;
}

export function startWith(src: string, match: string): boolean {
    const cleanedSrc = src.toUpperCase().trim().replace(' ', '');
    const cleanedMatch = match.toUpperCase().trim();

    if (cleanedSrc.length < cleanedMatch.length) {
        return false;
    }

    for (let i = 0; i < cleanedMatch.length; i++) {
        if (cleanedSrc.charAt(i) !== cleanedMatch.charAt(i)) {
            return false;
        }
    }

    return true;
}

export function cleanName(name: string): string {
    name = fullyReplaceString(name, '#', '');
    name = fullyReplaceString(name, '/', '\\');
    name = fullyReplaceString(name, '&', '%26')
    return name;
}

function fullyReplaceString(source: string, toRemove: string, replace: string): string {
    while (source.indexOf(toRemove) !== -1) {
        source = source.replace(toRemove, replace);
    }

    return source;
}

export function isWordIn(source: string, wordSeeked: string): boolean {
    const wordsToCheck = source.split(' ');
    for (const word of wordsToCheck) {
        if (word === wordSeeked) {
            return true;
        }
    }

    return false;
}

export function removeStringFromStartingStringToEndingString(source: string, start: string, end: string): string {
    if (!isWordIn(source, start)) {
        return source;
    }

    const stringToCheck = source.split(start);
    for (let i = 1; i < stringToCheck.length; i++) {
        const toCut = stringToCheck[i].split(end);

        source = source.replace((start + toCut[0]) + end, '');
        source = source.replace((start + toCut[0]), '');
    }

    source = source.trim();

    return endWith(source, end) ? source.slice(0, source.length - end.length) : source;
}

export function endWith(word: string, ending: string): boolean {
    for (let i = 0; i < ending.length; i++) {
        if (word[word.length - 1 - i] !== ending[ending.length - 1 - i]) {
            return false;
        }
    }

    return true;
}

export function wordWithoutPonctuation(word: string): string {
    return ponctuationSigns.includes(word.charAt(word.length - 1)) ? word.slice(0, word.length - 1) : word;
}

export function getComparator(s: string): string {
    for (const comparator of comparators) {
        if(s.trim().startsWith(comparator)){
            return comparator;
        }
    }

    return DEFAULT_COMPARATOR;
}

export function replaceAny(source: string, stringsToReplace: string[], replacement: string): string {
    for(const s of stringsToReplace) {
        source = source.replace(s, replacement);
    }

    return source;
}