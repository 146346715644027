import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  private static EXPIRATION = '-expiration';

  private static stringifiedNowWithFourMoreHours(): string {
    const date = new Date();
    date.setTime(date.getTime() + (4 * 60 * 60 * 1000));
    return JSON.stringify(date);
  }

  private static keyWithExpirationLabel(key: string): string {
    return key + StorageService.EXPIRATION;
  }


  public store<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
    localStorage.setItem(StorageService.keyWithExpirationLabel(key), StorageService.stringifiedNowWithFourMoreHours());
  }

  public get<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key));
  }

  recentLastStored(key: string): boolean {
    if (!localStorage.getItem(key)) { return false; }
    return this.get(StorageService.keyWithExpirationLabel(key)) < new Date();
  }
}
