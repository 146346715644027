<div *ngIf="this.rule == 'then'">
    Do A then do B, B happens after A, this is still only 1 effect. You must be able to do all mandatory parts to activate. If A doesn't happen, stop resolution here. If B cannot happen anymore, still resolve A.
</div>
<div *ngIf="this.rule == 'If'">
    On an activated effect, this effect will activate in the next available chain and cannot miss a timing.
</div>
<div *ngIf="this.rule == 'When'">
    If not mandatory, this effect will try to activate immediately after its condition has been met, and only if it was the last thing to happen. If mandatory: activate in the next available chain.
</div>
<div *ngIf="this.rule == 'andifyoudo'">
    Do A, and if you do, do B, both actions are considered simultaneous, this is still only 1 effect. You must be able to do all mandatory parts to activate. If A doesn't happen, stop resolution here. If B cannot happen anymore, still resolve A.
</div>
<div *ngIf="this.rule == 'fortherestofthisturn'">
    This restriction applies only to your actions AFTER this part of the effect.
</div>
<div *ngIf="this.rule == 'duringtheturnyouactivate'">
    This restriction checks your actions PRIOR to activating this card or effect. If you have not respected this restriction earlier this turn, you cannot activate this card or effect.
</div>
<div *ngIf="this.rule == 'QuickEffect'">
    This is a Spell Speed 2 effect. It can be activated in response to Spell Speed 1 and 2 effects, but not in response to Counter Traps. It is NOT a trigger effect.
</div>
<div *ngIf="this.rule == 'excavate'">
    Excavating means revealing the cards to both players from the Deck. These cards are still considered to be in the Deck.
</div>
<div *ngIf="this.rule == 'control'">
    Cards you “control” are the cards in your Monster Zone, Spell & Trap Zone, Field Zone, and Pendulum Zone.
</div>
<div *ngIf="this.rule == 'Tribut'">
    Tributing is sending a monster you control to the Graveyard. You can Tribute a face-up or face-down monster, unless otherwise specified.
</div>
<div *ngIf="this.rule == 'leavesthefield'">
    Leaving the field means being sent to the GY, banished, returned to the hand or Extra Deck. A card can NOT activate its effect when it leaves the field if it is returned to the Main Deck, or if it is used as XYZ Material.
</div>
<div *ngIf="this.rule == 'Set'">
    Setting a card means placing it face-down. Any face-down card on the field is Set.
</div>
<div *ngIf="this.rule == 'cost'">
    Cost or action to perform in order to activate the card or effect. Must be done before any response. This effect creates a chain link.
</div>
<div *ngIf="this.rule == 'condition'">
    Condition in which this effect activates.
</div>
<div *ngIf="this.rule == 'youcanonlyuse'">
    If the activation of this effect is negated, you cannot activate it again this turn.
</div>
<div *ngIf="this.rule == 'youcanonlyactivate'">
    If the activation of this effect is negated, you CAN attempt to activate it with another copy of the card this turn.
</div>
<div *ngIf="this.rule == 'instead'">
    Actions to perform INSTEAD of others are generally done passively and are not an activated effect, therefore these actions cannot be negated.
</div>
<div *ngIf="this.rule == 'startofdamagestep'">
    Face-down attacked monsters are not flipped yet. From this step on, you can only activate:
    <ul>
        <li>Cards/effects that specifically mention being activated during this step</li>
        <li>Cards/effects that directly modify ATK/DEF</li>
        <li>Cards/effects that negate the ACTIVATION of other cards and effects</li>
        <li>Counter trap cards</li>
        <li>Mandatory effects</li>
        <li>Trigger effects that activate from the movement of the involved card itself</li>
    </ul>
</div>
<div *ngIf="this.rule == 'beforedamagecalculation'">
    A face-down attacked monster is flipped face-up now, but FLIP effects are NOT activated yet.<br>Any continuous effect revealed IS applied immediatly, UNLESS it is an effect that would destroy the monster. Such effect would be applied after damage
    calculation.
    <br>If another continuous effect on the field prevents the revealed monster from staying on the field, it is not removed from the field until after damage calculation.<br> During this step, you can only activate:
    <ul>
        <li>Cards/effects that specifically mention being activated during this step</li>
        <li>Cards/effects that directly modify ATK/DEF</li>
        <li>Cards/effects that negate the ACTIVATION of other cards and effects</li>
        <li>Counter trap cards</li>
        <li>Mandatory effects</li>
        <li>Trigger effects that activate from the movement of the involved card itself</li>
    </ul>
</div>
<div *ngIf="this.rule == 'duringdamagecalculation'">
    Battle damage is calculated and inflicted during this step. It is now determined which monster(s) is/are destroyed by battle, however they are NOT sent to the GY yet.<br> If an action can prevent a monster from being destroyed by battle by doing something
    else instead, it is decided whether to apply it or not at this point. HOWEVER, such action will be performed at the end of the Damage Step.<br> In general, cards and effects that modify ATK/DEF cannot be activated at this point or for the rest of
    this Damage Step.<br> During this step you can only activate:
    <ul>
        <li>Cards/effects that specifically mention being activated during this step</li>
        <li>Cards/effects that negate the ACTIVATION of other cards and effects</li>
        <li>Counter trap cards</li>
        <li>Mandatory effects</li>
        <li>Trigger effects that activate from the movement of the involved card itself</li>
    </ul>
</div>
<div *ngIf="this.rule == 'afterdamagecalculation'">
    Monsters that were destroyed by battle are still not sent to the GY yet.<br>A flipped monster will now apply any continuous effect that would destroy it or send it to the GY UNLESS it was destroyed by battle.<br> During this step you can only activate:
    <ul>
        <li>FLIP effects and effects that activate when a monster is flipped face-up</li>
        <li>Cards/effects that activate when a player takes battle damage</li>
        <li>Cards/effects that specifically mention being activated during this step</li>
        <li>Cards/effects that negate the ACTIVATION of other cards and effects</li>
        <li>Counter trap cards</li>
        <li>Mandatory effects</li>
        <li>Trigger effects that activate from the movement of the involved card itself</li>
    </ul>
</div>
<div *ngIf="this.rule == 'endofdamagestep'">
    Monsters that were destroyed by battle are now sent to the GY or any other appropriate location. If an action was determined to be done to prevent the destruction, that action is performed now.<br> Effects that apply "until the end of the damage step"
    are still applying until after the resolution of all effects activated during this step. During this step you can only activate:
    <ul>
        <li>Most effects that activate when a monster is destroyed by battle</li>
        <li>Cards/effects that specifically mention being activated during this step</li>
        <li>Cards/effects that negate the ACTIVATION of other cards and effects</li>
        <li>Counter trap cards</li>
        <li>Mandatory effects</li>
        <li>Trigger effects that activate from the movement of the involved card itself</li>
    </ul>
</div>
<div *ngIf="this.rule == 'possess'">
    Cards in your “possession” include all cards you control, plus the cards in your hand, Deck, Extra Deck, Graveyard, your banished cards, cards in the middle of being Summoned to your side of the field, and Xyz Materials attached to monsters you control.
</div>
<div *ngIf="this.rule == 'also'">
    Do A, also do B, both effects are considered simultaneous. Neither part of the effect is required to resolve the other part, resolve as much as possible.
</div>