<app-header></app-header>
<h2><a href="/" id="home-button">Home</a> > <a href="/">Search results</a></h2>
<div class="container">
    <img class='card-image' [src]="this.card.card_images[0].image_url" />
    <div class="container__card-data">

        <h2>
            {{this.card.name}}
        </h2>
        <div class="container__setbutton-banlistinfo">
            <button (click)="changeSetDisplay()" class="display-button">{{this.displayButtonText}}</button>
            <p>TCG List: <span class="{{this.card.banlist_info ? this.card.banlist_info.ban_tcg : this.defaultBanListInfo.ban_tcg}}">{{this.card.banlist_info ? this.card.banlist_info.ban_tcg : this.defaultBanListInfo.ban_tcg}}</span></p>
        </div>

        <h3 *ngIf="this.displayOrHideSets">
            {{this.card.card_sets ? '' : 'No set yet'}}
            <div *ngFor="let set of this.card.card_sets, let index = index" class="sets" ngClass="{{set.set_rarity.replace(' ', '-')}}">
                <a (click)="this.showCardsBySet(set.set_code)">
          {{set.set_code}} - {{set.set_name}} ({{set.set_rarity}})
        </a>
            </div>
        </h3>

        <h3 *ngIf="!this.ranklessType.includes(this.card.type)">
            Level/Rank: {{this.card.level}}
        </h3>

        <h3>
            {{this.card.race}} {{this.card.type}}
        </h3>

        <div class="container__card-data__description">
            <p *ngFor="let text of this.textCutted" ngClass="{{text.types.join(' ')}}" title="{{text.describe}}" (click)="displayOrHideDescription(text.ruleName)">
                {{text.text}}
            </p>
        </div>

        <div class="helper">
            <p>Click a keyword or sentence to receive help from the library!</p>
        </div>

        <app-rule-description *ngFor="let rule of this.ruleDisplayed" rule="{{rule}}">

        </app-rule-description>
    </div>
</div>