import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BanListInfo} from 'src/app/utils/models/cards/ban-list-info';
import {Card, DefaultBanListInfo} from '../../utils/models/cards/card';
import {StorageService} from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  constructor(private httpClient: HttpClient, private readonly storageService: StorageService) {
  }

  cards = [];

  API_URL = 'https://db.ygoprodeck.com/api/v7/cardinfo.php';

  async getAllCards(): Promise<Card[]> {
    try {
      const a = (await this.httpClient.get<Card[]>(this.API_URL).toPromise())['data'];
      const cards = a.map((card: Card) => this.addCardBanInfo(card));
      return cards;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  private addCardBanInfo(card: Card): Card {
    card.banlist_info = this.getBanListInfoFromBanListInfo(card.banlist_info);

    return card;
  }

  private getBanListInfoFromBanListInfo(banListInfo: BanListInfo): BanListInfo {
    if (!banListInfo) {
      return new DefaultBanListInfo();
    }

    return this.fillBanList(banListInfo);
  }

  private fillBanList(banList: BanListInfo): BanListInfo {
    if (!banList.ban_goat) {
      banList.ban_goat = 'Allowed';
    }

    if (!banList.ban_ocg) {
      banList.ban_ocg = 'Allowed';
    }

    if (!banList.ban_tcg) {
      banList.ban_tcg = 'Allowed';
    }

    return banList;
  }

  async getCard(cardName: string): Promise<Card> {
    try {
      cardName = await this.fixCardName(cardName);
      const url = `${this.API_URL}?name=${cardName.replace('\\', '/')}`;
      return (await this.httpClient.get<Card>(url).toPromise())['data'][0];
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async fixCardName(card: string): Promise<string> {
    while (card.indexOf('\\') !== -1) {
      card = card.replace('\\', '/');
    }

    return card;
  }
}
