import { MatchInfo } from 'src/app/utils/models/match/matchInfo';
import { INSTEAD_RULE, AFTER_DAMAGE_STEP_RULE, ALSO_RESOLUTION_RULE, AND_IF_YOU_DO_RESOLUTION_RULE, AT_DAMAGE_STEP_START_RULE, CONDITION_RULE, CONTROL_RULE, COST_RULE, DURING_DAMAGE_STEP_RULE, EXCAVATE_RULE, FLIP_RULE, FOR_THE_REST_OF_THIS_TURN_RULE, IF_TRIGGER_RULE, LEAVE_FIELD_RULE, QUICK_EFFECT_RULE, SET_RULE, THEN_RESOLUTION_RULE, TRIBUTE_RULE, TRIBUTING_RULE, UNTIL_END_DAMAGE_STEP_RULE, WHEN_TRIGGER_RULE, YOU_CAN_ONLY_ACTIVATE_RULL, YOU_CAN_ONLY_USE_RULL, BEFORE_DAMAGE_RULE, POSSESS_RULE, ALSO_RULE } from 'src/app/utils/rules/ruleDeclaration.util';

// On parsing text, types are stacking. Some types needs to be unique if found.
export const uniqueTypes = ['effect-type', 'invocation-materials']

export const perfectMatchs: MatchInfo[] = [
    {
        keyword: { word: 'If', endings: [] },
        types: ['triggers'],
        describe: IF_TRIGGER_RULE,
        endChar: '',
        ruleName: 'If',
    },
    {
        keyword: { word: 'When', endings: [] },
        types: ['triggers'],
        describe: WHEN_TRIGGER_RULE,
        endChar: '',
        ruleName: 'When',
    },
    {
        keyword: { word: 'then', endings: [] },
        types: ['effect-flow'],
        describe: THEN_RESOLUTION_RULE,
        endChar: '',
        ruleName: 'then',
    },
    {
        keyword: { word: 'and if you do', endings: [] },
        types: ['effect-flow'],
        describe: AND_IF_YOU_DO_RESOLUTION_RULE,
        endChar: '',
        ruleName: 'andifyoudo',
    },
    {
        keyword: { word: 'for the rest of this turn', endings: [] },
        types: ['keyword'],
        describe: FOR_THE_REST_OF_THIS_TURN_RULE,
        endChar: '',
        ruleName: 'fortherestofthisturn',
    },
    {
        keyword: { word: 'during the turn you activate', endings: [] },
        types: ['keyword'],
        describe: ALSO_RESOLUTION_RULE,
        endChar: '',
        ruleName: 'duringtheturnyouactivate',
    },
    {
        keyword: { word: '(Quick Effect)', endings: [] },
        types: ['keyword'],
        describe: QUICK_EFFECT_RULE,
        endChar: '',
        ruleName: 'QuickEffect',
    },
    {
        keyword: { word: 'excavate', endings: ['d'] },
        types: ['keyword'],
        describe: EXCAVATE_RULE,
        endChar: '',
        ruleName: 'excavate',
    },
    {
        keyword: { word: 'control', endings: ['s', 'led', 'ler', 'ling'] },
        types: ['keyword'],
        describe: CONTROL_RULE,
        endChar: '',
        ruleName: 'control',
    },
    {
        keyword: { word: 'Tribut', endings: ['ing', 'es', 'e'] },
        types: ['keyword'],
        describe: TRIBUTE_RULE,
        endChar: '',
        ruleName: 'Tribut',
    },
    {
        keyword: { word: 'Flip', endings: [] },
        types: ['keyword'],
        describe: FLIP_RULE,
        endChar: '',
        ruleName: '',
    },
    {
        keyword: { word: 'start of the Damage Step', endings: [] },
        types: ['battletiming'],
        describe: AT_DAMAGE_STEP_START_RULE,
        endChar: '',
        ruleName: 'startofdamagestep',
    },
    {
        keyword: { word: 'before damage calculation', endings: [] },
        types: ['battletiming'],
        describe: BEFORE_DAMAGE_RULE,
        endChar: '',
        ruleName: 'beforedamagecalculation',
    },
    {
        keyword: { word: 'Before damage calculation', endings: [] },
        types: ['battletiming'],
        describe: BEFORE_DAMAGE_RULE,
        endChar: '',
        ruleName: 'beforedamagecalculation',
    },
    {
        keyword: { word: 'during damage calculation', endings: [] },
        types: ['battletiming'],
        describe: DURING_DAMAGE_STEP_RULE,
        endChar: '',
        ruleName: 'duringdamagecalculation',
    },
    {
        keyword: { word: 'During damage calculation', endings: [] },
        types: ['battletiming'],
        describe: DURING_DAMAGE_STEP_RULE,
        endChar: '',
        ruleName: 'duringdamagecalculation',
    },
    {
        keyword: { word: 'After damage calculation', endings: [] },
        types: ['battletiming'],
        describe: AFTER_DAMAGE_STEP_RULE,
        endChar: '',
        ruleName: 'afterdamagecalculation',
    },
    {
        keyword: { word: 'after damage calculation', endings: [] },
        types: ['battletiming'],
        describe: AFTER_DAMAGE_STEP_RULE,
        endChar: '',
        ruleName: 'afterdamagecalculation',
    },
    {
        keyword: { word: 'end of the Damage Step', endings: [] },
        types: ['battletiming'],
        describe: UNTIL_END_DAMAGE_STEP_RULE,
        endChar: '',
        ruleName: 'endofdamagestep',
    },
    {
        keyword: { word: 'leaves the field', endings: [] },
        types: ['keyword'],
        describe: LEAVE_FIELD_RULE,
        endChar: '',
        ruleName: 'leavesthefield',
    },
    {
        keyword: { word: 'Set', endings: [] },
        types: ['keyword'],
        describe: SET_RULE,
        endChar: '',
        ruleName: 'Set',
    },
    {
        keyword: { word: 'instead', endings: [] },
        types: ['instead'],
        describe: INSTEAD_RULE,
        endChar: '',
        ruleName: 'instead',
    },
    {
        keyword: { word: 'possess', endings: ['ion', 'es'] },
        types: ['keyword'],
        describe: POSSESS_RULE,
        endChar: '',
        ruleName: 'possess',
    },
    {
        keyword: { word: ', also', endings: [] },
        types: ['keyword'],
        describe: ALSO_RULE,
        endChar: '',
        ruleName: 'also',
    },
]

export const allBeforeMatchs: MatchInfo[] = [
    {
        keyword: { word: ';', endings: [] },
        types: ['cost'],
        describe: COST_RULE,
        endChar: '.',
        ruleName: 'cost',
    },
    {
        keyword: { word: '\n', endings: [] },
        types: ['invocation-materials'],
        describe: '',
        endChar: '.',
        ruleName: '',
    },
    {
        keyword: { word: ':', endings: [] },
        types: ['condition'],
        describe: CONDITION_RULE,
        endChar: '.',
        ruleName: 'condition',
    }

]

export const allAfterMatchs: MatchInfo[] = [
    {
        keyword: { word: '[', endings: [] },
        types: ['effect-type'],
        describe: '',
        endChar: '.',
        ruleName: '',
    },
    {
        keyword: { word: ' You can only use', endings: [] },
        types: ['restriction'],
        describe: YOU_CAN_ONLY_USE_RULL,
        endChar: '.',
        ruleName: 'youcanonlyuse',
    },
    {
        keyword: { word: ' You can only activate', endings: [] },
        types: ['restriction'],
        describe: YOU_CAN_ONLY_ACTIVATE_RULL,
        endChar: '.',
        ruleName: 'youcanonlyactivate',
    }

]