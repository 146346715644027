export function flatMap<T, U>(array: T[], callbackfn: (value: T, index: number, array: T[]) => U[]): U[] {
  return Array.prototype.concat(...array.map(callbackfn));
}

export function removeRedondantElementsByProperty(array: any[]): any[] {
  const singlyRepresentedElements: any[] = [];

  for (const element of array) {
    if (!singlyRepresentedElements.includes(element)) {
      singlyRepresentedElements.push(element);
    }
  }

  return singlyRepresentedElements;
}


export function getArrayOfUniqueParametersFromArrayOfObjects(elements: any, property: string): any[] {
 
  const arrayOfElements = elements.map(element => element[property]);
  
  return arrayOfElements.filter(function (item, pos) { return arrayOfElements.indexOf(item) == pos });  
}