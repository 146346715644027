import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CardPannelComponent} from './components/card-pannel/card-pannel.component';
import {LibraryComponent} from './components/library/library.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';


const routes: Routes = [
  {path: '', component: LibraryComponent},
  {path: 'card/:name', component: CardPannelComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
