import { CardSet } from './cardSet'
import { CardImages } from './cardImages'
import { BanListInfo } from './ban-list-info';

export class DefaultBanListInfo  {
    ban_goat: String =  "Allowed";
    ban_ocg: String =  "Allowed";
    ban_tcg: String =  "Allowed";
}

export class Card {
    id: Number;
    atk: Number;
    def: Number;
    attribute: string;
    archetype: string;
    scale: Number;
    name: string;
    type: string;
    desc: string;
    race: string;
    level: Number;
    displayCard: boolean;
    card_sets: CardSet[];
    card_images: CardImages[];
    linkval: Number;
    banlist_info: BanListInfo = new DefaultBanListInfo();
}