<form [formGroup]="form">
    <fieldset>
        <legend>Login</legend>
        <div class="form-field">
            <label>Username:</label>
            <input name="username" formControlName="username">
        </div>
        <div class="form-field">
            <label>Password:</label>
            <input name="password" formControlName="password" 
                   type="password">
        </div>
    </fieldset>
    <div>
        <div class="form-buttons">
            <button class="button button-primary" 
                    (click)="login()">Login</button>
        </div>
        <div class="form-buttons">
            <button class="button button-primary" 
                    (click)="register()">Register</button>
        </div>
    </div>

</form>