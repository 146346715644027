import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  hasKey(key: string) {
    if (!localStorage.getItem(key)) return false;
    return true;
  }

  put(key: string, value: string) {
    localStorage.setItem(key, this.encode(value))
  }

  get(key: string) {
    return this.decode(localStorage.getItem(key))
  }


  encode(value: string): string {
    return btoa(value);
  }

  decode(value: string): string {
    return atob(value);
  }

  clear() {
    localStorage.removeItem('filter');
  }
}
