import { Injectable } from '@angular/core';
import { EffectText } from 'src/app/utils/models/effect-text/effectText';
import { MatchInfo } from 'src/app/utils/models/match/matchInfo';
import { endWith, wordWithoutPonctuation } from 'src/app/utils/string/string.util';
import { allAfterMatchs, allBeforeMatchs, perfectMatchs, uniqueTypes } from './keyword';

@Injectable({
  providedIn: 'root'
})
export class TextCutterService {

  constructor() { }

  matchsList = [
    {
      matchs: allBeforeMatchs,
      method: this.allBefore,
    },
    {
      matchs: allAfterMatchs,
      method: this.allAfter,
    },
    {
      matchs: perfectMatchs,
      method: this.getExact,
    }
  ]

  cutText(text: string): EffectText[] {
    text = text.replace('----------------------------------------', '');
    let result: EffectText[] = [{ text, types: ['effect'], describe: '', ruleName: '' }];
    for (const matchList of this.matchsList) {
      result = this.cutTextByMatchsAndMethod(result, matchList.matchs, matchList.method);
    }
    return result;
  }

  cutTextByMatchsAndMethod(effectTexts: EffectText[], matchs: MatchInfo[], method): EffectText[] {
    for (const match of matchs) {
      effectTexts = effectTexts.map(text => method(text.text, text.types, text.describe, match, text.ruleName)).reduce((acc, val) => acc.concat(val), [])
    }

    return effectTexts;
  }

  private allBefore(text: string, initialState: string[] = ['effect'], previousDescribe: string = '', matchInfo: MatchInfo, previousRule: ''): EffectText[] {
    const { endChar, keyword, describe, types, ruleName } = matchInfo;
    const cuttedText: EffectText[] = [];
    let tested_text: string = '';
    let ignore: boolean = false;

    for (const char of text) {
      tested_text += char;
      if (char === '"') ignore = ignore === false ? true : false;
      switch (char) {
        case endChar:
          if (ignore) break;
          cuttedText.push({ text: tested_text, types: initialState, describe: previousDescribe, ruleName: previousRule })
          tested_text = '';
          break;

        case keyword.word:
          cuttedText.push({ text: tested_text, types: TextCutterService.uniqueOrValues(initialState, types), describe, ruleName })
          tested_text = '';
          break;
      }
    }

    tested_text === '' ? null : cuttedText.push({ text: tested_text, types: initialState, describe: previousDescribe, ruleName: previousRule });
    return cuttedText;
  }

  private allAfter(text: string, initialState: string[] = ['effect'], previousDescribe: string = '', matchInfo: MatchInfo, previousRule: string) {
    const { endChar, keyword, describe, types, ruleName } = matchInfo;
    const cuttedText: EffectText[] = [];

    let tested_text: string = '';
    let is_found: boolean = false;
    let ignore = false;

    for (const char of text) {
      tested_text += char;
      if (char === '"') ignore = ignore === false ? true : false;
      if (keyword.word.length !== 1) {
        if (keyword.word.toUpperCase() === tested_text.toUpperCase()) {
          is_found = true;
        }
      } else {
        if (keyword.word.toUpperCase() === char.toUpperCase()) {
          is_found = true;
        }
      }

      if (char.toUpperCase() === endChar.toUpperCase() && !ignore && is_found) {
        cuttedText.push({ text: tested_text, types: TextCutterService.uniqueOrValues(initialState, types), describe, ruleName });
        tested_text = '';
        is_found = false;
      }
    }

    tested_text === '' ? '' : cuttedText.push({ text: tested_text, types: is_found ? TextCutterService.uniqueOrValues(initialState, types) : initialState, describe: previousDescribe, ruleName: previousRule });
    return cuttedText;
  }

  static getExactWithSpaceInKeyword(text: string, initialState: string[] = ['effect'], previousDescribe: string = '', matchInfo: MatchInfo, previousRule: string): EffectText[] {
    const { keyword, types, describe, ruleName } = matchInfo;
    const seekedWord = keyword.word;
    const cuttedStringInEffectText: EffectText[] = [];
    let unrelatedSentence: string = '';
    let checkedSentence: string = '';

    for (let i = 0; i < text.length; i++) {
      checkedSentence += text[i];
      if (checkedSentence.length > seekedWord.length) {
        unrelatedSentence += checkedSentence[0];
        checkedSentence = checkedSentence.slice(1, checkedSentence.length);
      }

      if (checkedSentence === seekedWord) {
        cuttedStringInEffectText.push({ text: unrelatedSentence, types: initialState, describe: previousDescribe, ruleName: previousRule });
        cuttedStringInEffectText.push({ text: seekedWord, types: TextCutterService.uniqueOrValues(initialState, types), describe, ruleName });
        unrelatedSentence = '';
        checkedSentence = '';
      }
    }


    if (unrelatedSentence !== '' || checkedSentence !== '') {
      cuttedStringInEffectText.push({ text: (unrelatedSentence + checkedSentence), types: initialState, describe: previousDescribe, ruleName: previousRule })
    }

    return cuttedStringInEffectText;
  }

  private getExact(text: string, initialState: string[] = ['effect'], previousDescribe: string = '', matchInfo: MatchInfo, previousRule: string): EffectText[] {
    if (matchInfo.keyword.word.indexOf(' ') !== -1) { return TextCutterService.getExactWithSpaceInKeyword(text, initialState, previousDescribe, matchInfo, previousRule); }

    const { keyword, types, describe, ruleName } = matchInfo;
    const cuttedStringInEffectText: EffectText[] = [];
    const cuttedString = text.split(' ');
    let normalSentence: string = '';

    for (const word of cuttedString) {
      const ponctuationlessWord = wordWithoutPonctuation(word);
      if (ponctuationlessWord.length < keyword.word.length) {
        normalSentence += (word + ' ');
      } else {
        let isDifferent: boolean = false;
        for (let i = 0; i < keyword.word.length && !isDifferent; i++) {
          if (ponctuationlessWord[i] !== keyword.word[i]) {
            isDifferent = true;
          }
        }

        if (!isDifferent) {
          if (keyword.endings.length === 0 || keyword.endings.map(ending => endWith(ponctuationlessWord, ending)).filter(val => val === true).length >= 1) {
            cuttedStringInEffectText.push({ text: normalSentence, types: initialState, describe: previousDescribe, ruleName: previousRule })
            cuttedStringInEffectText.push({ text: word, types: TextCutterService.uniqueOrValues(initialState, types), describe, ruleName })
            normalSentence = '';
          } else {
            normalSentence += (word + ' ');
            continue;
          }
        } else {
          normalSentence += (word + ' ');
        }
      }
    }

    if (normalSentence !== '') {
      cuttedStringInEffectText.push({ text: normalSentence, types: initialState, describe: previousDescribe, ruleName: previousRule })
    }

    return cuttedStringInEffectText;
  }


  static uniqueOrValues(initialTypes: string[], types: string[]): string[] {
    return uniqueTypes.includes(types[0]) ? types : initialTypes.concat(types);
  }
}
