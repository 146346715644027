import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }

  register(username: string, password: string): Observable<any> {
    return this.http.post(environment.serviceUrl + '/users/registration', {
      username,
      password,
    });
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post(environment.serviceUrl + '/users/authentication', {
      username,
      password,
    });
  }

  isLoggedIn(): Observable<any> {
    return this.http.get(environment.serviceUrl + '/users/me');
  }
}
