import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-clean-button',
  templateUrl: './clean-button.component.html',
  styleUrls: ['./clean-button.component.scss']
})
export class CleanButtonComponent implements OnInit {

  @Input("text")
  text: string;

  @Output('clearEmission')
  clearEmission: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  emitClear(): void {
    this.clearEmission.emit('clearEmission');
  }

}
