import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardsService } from '../../services/cards/cards.service';
import { Card } from '../../utils/models/cards/card'
import { DefaultBanListInfo } from '../../utils/models/cards/card';
import { ActivatedRoute, Router } from '@angular/router';
import { EffectText } from '../../utils/models/effect-text/effectText';
import { TextCutterService } from '../../services/text-cutter/text-cutter.service'
import { CacheService } from '../../services/cache/cache.service';
import { removeRedondantElementsByProperty } from '../../utils/array/array.utils';
import { EmptyCard } from '../../utils/mocks/card/EmptyCard.mock';
import { oneTypeOnlyCardTypes, ranklessType } from '../../utils/card.util';

@Component({
  selector: 'app-card-pannel',
  templateUrl: './card-pannel.component.html',
  styleUrls: ['./card-pannel.component.scss']
})
export class CardPannelComponent implements OnInit {

  // Special card rules
  ranklessType: string[] = ranklessType;
  oneTypeOnly: string[] = oneTypeOnlyCardTypes;

  // Mocked Data to prevent error on card launching
  card: Card = new EmptyCard;
  defaultBanListInfo = new DefaultBanListInfo;
  textCutted: EffectText[] = [];
  cardName: string = "";

  // Rule description section
  isDescriptionDisplayed: boolean = false;
  ruleDisplayed: string[] = [];

  // Method import section
  removeRedondantElementsByProperty = removeRedondantElementsByProperty;

  // Display Hide sets section
  displayOrHideSets: boolean = false;
  displayButtonTextOptions: string[] = ['Display sets', 'Hide sets'];
  displayButtonText: string = 'Display sets';


  constructor(private cardService: CardsService, private route: ActivatedRoute, private textCutterService: TextCutterService, private cacheService: CacheService, private router: Router) {
  }

  async ngOnInit() {
    this.cardName = this.route.snapshot.paramMap.get('name');

    this.card = await this.cardService.getCard(this.cardName);
    console.log(this.card);

    if (!(this.card.type === "Normal Monster")) {
      this.textCutted = this.textCutterService.cutText(this.card.desc);
    } else {
      this.textCutted = [{ text: this.card.desc, types: ['regular'], describe: '', ruleName: '' }]
    }

    document.getElementById("home-button").addEventListener('click', event => this.cacheService.clear());
  }

  OnDestroy() {
    this.cacheService.clear()
  }

  displayOrHideDescription(ruleName: string): void {
    if (this.ruleDisplayed.includes(ruleName)) {
      this.ruleDisplayed.splice(this.ruleDisplayed.indexOf(ruleName), 1);
    } else {
      this.ruleDisplayed[0] = ruleName;
    }
  }

  changeSetDisplay(): void {
    this.displayOrHideSets = !this.displayOrHideSets;
    this.displayButtonText = this.displayButtonTextOptions[(this.displayButtonTextOptions.indexOf(this.displayButtonText) + 1) % this.displayButtonTextOptions.length];
  }

  showCardsBySet(set: string) {
    this.router.navigateByUrl('/', { state: { filters: [`set: ${set.split('-')[0]}`] } });
  }
}
