<div *ngIf="this.selector === false" style="display: inline;">
  <input type="text" [(ngModel)]="this.selectedElement" (input)="autocomplete()" list="items"
    (keydown)="getKey($event)" />
  <datalist id="items">
    <option *ngFor="let item of this.filteredValues" data-value="{{item}}">{{item}}</option>
  </datalist>
</div>
<div *ngIf="this.selector === true" style="display: inline;">
  <select [(ngModel)]="this.selectedElement" (change)="emitFilterString()">
    <option></option>
    <option *ngFor="let item of this.selection">{{item}}</option>
  </select>
</div>
