import { BooleanOperation } from "../models/operation/boolean-operation";

export const operations: BooleanOperation[] = [
/**
 * L'ordre des opérateur est important dans 
 * la mesure où la méthode de récupérations des opérateurs dans la string itère sur cette liste
 */
    {
        character: '!=',
        apply: different,
    },
    {
        character: '<=',
        apply: inferiorOrEqual,
    },
    {
        character: '>=',
        apply: superiorOrEqual,
    },
    {
        character: '=',
        apply: equal,
    },
    {
        character: '>',
        apply: strictlySuperior,
    },
    {
        character: '<',
        apply: strictlyInferior,
    },

]

export function getBooleanOperation(character: string): BooleanOperation {
    const operation = operations.filter(operation => operation.character === character);    
    return operation.length > 0 ? operation[0] : null;
}



function strictlySuperior(number1: Number, number2: Number): boolean {
    if (number1 > number2) return true;
    return false;
}

function superiorOrEqual(number1: Number, number2: Number): boolean {
    if (number1 >= number2) return true;
    return false;
}

function equal(number1: Number, number2: Number): boolean {
    if (number1 == number2) return true;
    return false;
}

function strictlyInferior(number1: Number, number2: Number): boolean {
    if (number1 < number2) return true;
    return false;
}

function inferiorOrEqual(number1: Number, number2: Number): boolean {
    if (number1 <= number2) return true;
    return false;
}

function different(number1: Number, number2: Number): boolean {
    if (number1 !== number2) return true;
    return false;
}