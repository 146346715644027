import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rule-description',
  templateUrl: './rule-description.component.html',
  styleUrls: ['./rule-description.component.scss']
})
export class RuleDescriptionComponent implements OnInit {

  @Input("rule")
  rule: string;

  constructor() { }

  ngOnInit(): void {
  }

}
