import { BanListInfo } from '../../models/cards/ban-list-info';
import { Card, DefaultBanListInfo } from '../../models/cards/card';
import { CardImages } from '../../models/cards/cardImages';
import { CardSet } from '../../models/cards/cardSet';
import { EmptyImage } from './EmptyImage.mock';
import { EmptySet } from './EmptySet.mock';

export class EmptyCard implements Card {
    id: Number = null
    atk: Number = null;
    def: Number = null;
    linkval: null;
    attribute: string = null;
    archetype: string = null;
    scale: Number = null;
    name: string = '';
    type: string = '';
    desc: string = '';
    race: string = null;
    level: Number = null;
    displayCard: boolean = false;
    card_sets: CardSet[] = [new EmptySet];
    card_images: CardImages[] = [new EmptyImage];
    public banlist_info: BanListInfo = new DefaultBanListInfo();
}