import { LibraryComponent } from 'src/app/components/library/library.component';
import { Card } from '../models/cards/card';
import { getBooleanOperation, operations } from '../operations/operation.util';
import { comparators, getComparator, isStringIn, replaceAny } from '../string/string.util';

export const BY_TYPE_CHARACTER = 'c:';
export const BY_RACE_CHARACTER = 'r:';
export const BY_LEVEL_CHARACTER = 'l:';
export const BY_ARCHETYPE_CHARACTER = 'a:';
export const BY_TEXT_IN_EFFECT_CHARACTER = 'x:';
export const BY_SETCODE_CHARACTER = 'set:';
export const BY_SCALE_CHARACTER = 'sca:';
export const BY_ATTRIBUT_CHARACTER = 'att:';
export const BY_ATK_CHARACTER = 'atk:';
export const BY_DEF_CHARACTER = 'def:';
export const BY_LINK_LEVEL_CHARACTER = 'lin:';
export const BY_ATK_AND_DEF_CHARACTER = "ad:";
export const BY_RARITY_CHARACTER = "rar:";
export const BY_BANLIST_CHARACTER = "ban:";

const ALL_OPERATORS = operations.map(operation => operation.character);
const MATCH_OR_NO_MATCH_OPERATORS = ['=', '!='];

// This array will automatically remove weird values in filter selection
export const TYPE_REMOVED = [undefined, 'Andrew', ''];

export const specialFilters = [
    {
        id: 'type',
        label: 'Card Type ',
        isUnique: true,
        selector: true,
        type: String,
        selection: [],
        keychar: BY_TYPE_CHARACTER,
        operators: [],
        action: filterByStringStat('type', BY_TYPE_CHARACTER),
    },
    {
        id: 'attribute',
        isUnique: true,
        selector: true,
        label: 'Attribute ',
        type: String,
        selection: [],
        keychar: BY_ATTRIBUT_CHARACTER,
        operators: [],
        action: filterByStringStat('attribute', BY_ATTRIBUT_CHARACTER),
    },
    {
        id: 'race',
        isUnique: true,
        selector: true,
        label: 'Type ',
        type: String,
        selection: [],
        keychar: BY_RACE_CHARACTER,
        operators: [],
        action: filterBySpecificStringStat('race', BY_RACE_CHARACTER),
    },
    {
        id: 'level',
        isUnique: true,
        selector: true,
        label: 'Level/Rank ',
        type: Number,
        selection: [],
        keychar: BY_LEVEL_CHARACTER,
        operators: ALL_OPERATORS,
        action: filterByMathStat(['level'], BY_LEVEL_CHARACTER),
    },
    {
        id: 'scale',
        isUnique: true,
        selector: true,
        label: 'Pend. Scale ',
        type: Number,
        selection: [],
        keychar: BY_SCALE_CHARACTER,
        operators: ALL_OPERATORS,
        action: filterByMathStat(['scale'], BY_SCALE_CHARACTER),
    },
    {
        id: 'linkval',
        isUnique: true,
        selector: true,
        label: 'Link Rating ',
        type: Number,
        selection: [],
        keychar: BY_LINK_LEVEL_CHARACTER,
        operators: ALL_OPERATORS,
        action: filterByMathStat(['linkval'], BY_LINK_LEVEL_CHARACTER),
    },
    {
        id: 'archetype',
        isUnique: true,
        selector: false,
        label: 'Archetype ',
        type: String,
        selection: [],
        keychar: BY_ARCHETYPE_CHARACTER,
        operators: [],
        action: filterByStringStat('archetype', BY_ARCHETYPE_CHARACTER),
    },
    {
        id: 'set',
        isUnique: true,
        //TODO determine if setcode will or will not be a selector
        selector: false,
        label: 'Set Code ',
        type: String,
        selection: [],
        keychar: BY_SETCODE_CHARACTER,
        operators: [],
        action: filterBySetcode,
    },
    {
        id: 'text',
        isUnique: true,
        //TODO We may implement a list of keyword but the powerful will be weaker if so
        selector: false,
        label: 'Word in text ',
        type: String,
        selection: [],
        keychar: BY_TEXT_IN_EFFECT_CHARACTER,
        operators: [],
        action: filterByStringStat('desc', BY_TEXT_IN_EFFECT_CHARACTER),
    },
    {
        id: 'atk',
        isUnique: true,
        selector: false,
        label: 'ATK ',
        type: String,
        selection: [],
        keychar: BY_ATK_CHARACTER,
        operators: ALL_OPERATORS,
        action: filterByMathStat(['atk'], BY_ATK_CHARACTER),
    },
    {
        id: 'def',
        isUnique: true,
        selector: false,
        label: 'DEF ',
        type: String,
        selection: [],
        keychar: BY_DEF_CHARACTER,
        operators: ALL_OPERATORS,
        action: filterByMathStat(['def'], BY_DEF_CHARACTER),
    },
    {
        id: 'atk+def',
        isUnique: true,
        selector: false,
        label: 'ATK + DEF ',
        type: String,
        selection: [],
        keychar: BY_ATK_AND_DEF_CHARACTER,
        operators: ALL_OPERATORS,
        action: filterByMathStat(['atk','def'], BY_ATK_AND_DEF_CHARACTER),
    },
    {
        id: 'card_sets',
        isUnique: true,
        selector: true,
        label: 'Rarity ',
        type: Card,
        selection: [],
        keychar: BY_RARITY_CHARACTER,
        operators: [],
        action: filterByRarity,
  },
  {
        id: 'banlist_info',
        isUnique: true,
        selector: true,
        label: 'TCG Banlist status ',
        type: Card,
        selection: [],
        keychar: BY_BANLIST_CHARACTER,
        operators: [],
        action: filterByBanlist,
  }
]

function filterBySetcode(context: LibraryComponent, cards: Card[], filterParameter: string) {
    context.displayedCards = cards
        .filter(card => card.card_sets
            && card.card_sets
                .map(set => set.set_code.split('-')[0].toLowerCase())
                .includes(filterParameter.replace(BY_SETCODE_CHARACTER, '').trim().toLowerCase()));
}

function filterByMathStat(stats: string[], removedCharacter: string) {
    return (context: LibraryComponent, cards: Card[], filterParameter: string) => {
        const cleanedString = filterParameter.replace(removedCharacter, '');

        const comparator = getComparator(cleanedString);        
        const amountOfStat = parseInt(replaceAny(cleanedString, comparators, ''));

        if (amountOfStat === 0 || amountOfStat) {
            
            context.displayedCards = cards
            .filter(card => 
                getBooleanOperation(comparator).apply(getCardCumulatedStats(stats, card), amountOfStat) 
                && isAMonsterCardType(card));
        } else {
            context.displayedCards = [];
        }
    }
}

function getCardCumulatedStats(stats: string[], card: Card): Number {
    let cardStat = 0;
    for(const stat of stats) {
        cardStat += card[stat];
    }

    return cardStat
}

function filterBySpecificStringStat(stat: string, removedCharacter: string) {
    return (context: LibraryComponent, cards: Card[], filterParameter: string) => {
        const searchedValue = filterParameter.replace(removedCharacter, '').toLowerCase().trim();
        const filteredCards = cards.filter(card => card[stat] != undefined);
        context.displayedCards = filteredCards.filter(card => (card[stat] as string).toLowerCase() == searchedValue);

    }
}

function filterByStringStat(stat: string, removedCharacter: string) {
    return (context: LibraryComponent, cards: Card[], filterParameter: string) => {
        const filteredCards = cards.filter(card => card[stat] != undefined);
        context.displayedCards = filteredCards.filter(card => isStringIn(card[stat], filterParameter.replace(removedCharacter, '')))
    }
}

export function generateFilterStringFromFilterArrays(filters: string[]): string {
    let filterString: string = '';

    for (let i = 0; i < filters.length; i++) {
        if (i !== 0) {
            filterString += ' & ';
        }
        filterString += filters[i];
    }

    return filterString;
}

function isAMonsterCardType(card: Card): boolean {
    if (card.type.includes('Monster')) return true;

    return false;
}

function filterByRarity(context: LibraryComponent, cards: Card[], filterParameter: string) {
      context.displayedCards = cards
      .filter(card => card.card_sets
        && card.card_sets
          .map(set => set.set_rarity.toLowerCase())
          .reduce((accumulator, value) => accumulator.concat(value), [])
          .includes(filterParameter.replace(BY_RARITY_CHARACTER, '').trim().toLowerCase()));
}

function filterByBanlist(context: LibraryComponent, cards: Card[], filterParameter: string) {
    context.displayedCards = cards
    .filter(card => card.banlist_info
        .ban_tcg.toLowerCase() === (filterParameter.replace(BY_BANLIST_CHARACTER, '').trim().toLowerCase()));
}
