import { Component, HostListener } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Yugibrary - A rule comprehension assistant';

  kCode: string[] = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'a', 'b'];
  CODE_LENGTH: number = this.kCode.length;
  inputedCode: string[] = [];

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.tryKonamiCode(event.key)
  }

  tryKonamiCode(key: string) {
    this.inputedCode.push(key);

    if (this.inputedCode.length > this.CODE_LENGTH) {
      this.inputedCode = this.inputedCode.slice(1, this.CODE_LENGTH + 1);
    }

    if (this.inputedCode.length !== this.kCode.length) return;
    for (let i = 0; i < this.inputedCode.length; i++) {
      if (this.inputedCode[i] !== this.kCode[i]) {
        return;
      }
    }

    this.konamiCodeTime();

  }

  konamiCodeTime(): void {
    alert('Konami code activated. Prepare for your computer to be ours.');
  }
}
